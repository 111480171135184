import React from "react"
import Layout from "../components/layout"
import ToolsSlider from "../components/tools"
import Product from "../components/product"
import ProductImg from "../images/product.jpg"
import { Link } from "gatsby"

const arr = [1 , 2 , 3 , 1 , 1 , 2 , 3 , 1 , 1 , 2 , 3 , 1 , 1 , 2 , 3 , 1];

function Accessories() {
  return(
    <Layout>
      <div id="productSlider">
        <ToolsSlider/>
      </div>

      {/*<div className="fine-headline text-uppercase ">*/}
      {/*  <p className=''>*/}
      {/*    Choose one to find your tools*/}
      {/*  </p>*/}
      {/*  <h2>*/}
      {/*    Category*/}
      {/*  </h2>*/}
      {/*</div>*/}

      {/*<div className="category-wrapper container">*/}
      {/*  <div className="row row-wrapper mt-4 mb-4">*/}
      {/*    <div className="col-sm-12 col-lg-4 main-tool cate-bg cate-1 d-flex align-items-center justify-content-center">*/}
      {/*      <a href="#" className="cate" className="cate">*/}
      {/*        Hard Tools*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div className="col-sm-12 col-lg-8">*/}
      {/*      <div className="row first-row">*/}
      {/*        <div className="col-sm-12 col-lg-8 d-flex cate-bg align-items-center justify-content-center cate-2">*/}
      {/*          <a href="#" className="cate">*/}
      {/*            Agriculture Machineries*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*        <div className="col-sm-12 col-lg-4 d-flex cate-bg align-items-center justify-content-center cate-3">*/}
      {/*          <a href="#" className="cate">*/}
      {/*            Power tools*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <div className="row first-row">*/}
      {/*        <div className="col-sm-12 col-lg-8 d-flex cate-bg align-items-center justify-content-center cate-4">*/}
      {/*          <a href="#" className="cate">*/}
      {/*            Wood working*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*        <div className="col-sm-12 col-lg-4 d-flex cate-bg align-items-center justify-content-center cate-5">*/}
      {/*          <a href="#" className="cate">*/}
      {/*            Light Engineering*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}


      <div className="container text-center">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Find your accessories
          </p>
          <h2>
            All accessories & attachments
          </h2>
        </div>

        <div className="product-info-wrapper row">
          {arr.map((name, index) => (
            <div className="col-lg-3">
              <a href="#" className="product-link">
                <div key={index} className="bg-light product-slider-content">
                  <div className="product-img">
                    <img src={ProductImg} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    12V Max Cordless Power Toolsdsadsadsadsadsadsadsadsadsadsa
                  </p>
                  <div className="product-btn d-flex flex-column">
                    <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link>
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>

        <button type="button" className="find-more-product">More</button>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our accessories
          </p>
          <h2>
            Power tool accessories
          </h2>
        </div>
        <Product/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our attachments
          </p>
          <h2>
            Power tool attachments
          </h2>
        </div>
        <Product/>
      </div>

      <div className="container">
        <div className="fine-headline text-uppercase ">
          <p className=''>
            Discover our new products
          </p>
          <h2>
            New Products
          </h2>
        </div>
        <Product/>
      </div>

    </Layout>
  )
}

export default Accessories