import React from "react"
import Slider from "react-slick"
import {Link} from "gatsby"
import ProductImg from "../images/product.jpg"
 // array to loop data
const arr = [1 , 2 , 3 , 1 , 2 , 3 , 3 , 1 , 2 , 3];

function Product() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        }
      }
    ]
  };
  return (
    <>
      <div id="productSlider">
        <div className="product-slider">
          <Slider {...settings}>
            {arr.map((name, index) => (
              <a href="#" className="product-link">
                <div key={index} className="product-slider-content">
                  <div className="product-img">
                    <img src={ProductImg} alt="product image" width="100%"/>
                  </div>
                  <p className="product-tittle">
                    12V Max Cordless Power Toolsdsadsadsadsadsadsadsadsadsadsa
                  </p>
                  <div className="product-btn d-flex flex-column">
                    <Link to="/contact" className="text-uppercase mr-0 ml-0 p-2">
                      inquiry
                    </Link>
                    {/*<button type="button" className="text-uppercase">*/}
                    {/*  Order*/}
                    {/*</button>*/}
                  </div>
                </div>
              </a>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );

  // functions start here

}

export default Product