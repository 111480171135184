import React from "react"
import Slider from "react-slick"

function ToolsSlider() {
  var settings = {
    autoplay:true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
  };
  return(
    <>
      <div id="toolsSlider" className="hero-slider">
          <div className="tools-slider h-100 ">
            <Slider {...settings}>
              <div className="h-100 bg-1"></div>
              <div className="h-100 bg-2"></div>
              <div className="h-100 bg-3"></div>
            </Slider>
          </div>
      </div>
    </>
  )
}

export default ToolsSlider